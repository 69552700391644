<template>
<div>
    <slot></slot>
    <div class="trigger" ref="trigger"></div>
</div>
</template>

<script>
export default {
    name: 'infinite-scroll-container',
    data () {
        return {
            observer: null
        };
    },
    mounted () {
        const that = this;
        this.observer = new IntersectionObserver(function (entries) {
            that.handleIntersection(entries[0]);
        }, { threshold: 0 });
        this.observer.observe(this.$refs.trigger);
    },
    destroyed () {
        this.observer.disconnect();
    },
    methods: {
        handleIntersection (entry) {
            if (entry.isIntersecting) {
                this.$emit('load-next');
            }
        }
    }
};
</script>
