<template>
    <div>
        <validated-input icon="fa fa-search" v-model="search" clear-icon="fa fa-close"/>
        <div class="scrollmenu mb-3" >
            <a v-for="(item, index) in categoryList" v-bind:key="index" @click="FilterCat(item)">{{ item.name }}</a>
        </div>
        <infinite-scroll-container class="product-list" @load-next="loadNext">
            <div class="product-card bs-1" v-for="(product, index) in products" v-bind:key="index">
                <img src="../../../assets/img/No_image_available.svg" class="product-img" :alt="product.name"
                     v-if="!product.img"/>
                <img :src="product.img" alt="Image" class="product-img" v-else>
                <strong class="text-uppercase mt-2 text-primary">{{ product.name }}</strong>
                <hr/>
                <div class="product-footer">
                    <div class="price">
                        Rs.
                        <span class="display-price"
                              v-if="product.display_price !== product.selling_price">
                            <cur class="selling-price" :value="product.display_price"/></span>
                        <cur class="selling-price" :value="product.selling_price"/>
                    </div>
                    <div class="add-to-cart my-3 bs-11" :class="product.loading ? 'loading': ''">
                        <div v-if="product.count>0" class="btn-group-tight">
                            <c-btn icon="fa fa-minus" @click.prevent="addToCart(product, 'minus')"/>
                            <b class="py-2 px-3" v-html="product.count"></b>
                            <c-btn color="primary" icon="fa fa-plus" @click.prevent="addToCart(product, 'plus')"/>
                        </div>
                        <div v-else>
                            <btn title="add to cart" @click="addToCart(product, 'first')">
                                Add to cart
                            </btn>
                        </div>
                    </div>
                </div>
            </div>
        </infinite-scroll-container>
        <Loader v-if="loading"></Loader>
    </div>
</template>

<script>
import axios from 'secure-axios';
import urls from '../../../data/urls';
import { mapActions, mapGetters } from 'vuex';
import CardButton from '../../../components/CardButton';
import Loader from '../../../views/loader/loader';
import InfiniteScrollContainer from '../../../components/InfiniteScroll';
import debounce from 'lodash.debounce';
import Cur from '../../../components/Currency';

export default {
    name: 'ShopCatalog',
    components: {
        Cur,
        InfiniteScrollContainer,
        'c-btn': CardButton,
        Loader
    },
    data () {
        return {
            product: '',
            loading: false,
            prodLoading: false,
            page: 0,
            isLast: false,
            search: '',
            category: '',
            categoryList: [],
            model: {
                id: '1',
                name: '',
                description: '',
                category: '',
                price: ''
            },
            rules: {
                name: {
                    required: true
                },
                description: {
                    required: true
                },
                category: {
                    required: true
                },
                price: {
                    required: true
                }
            },
            products: [],
            data: []
        };
    },

    watch: {
        search: debounce(function () {
            this.products = [];
            this.page = 0;
            this.isLast = false;
            this.loadNext();
        }, 500),
        category: debounce(function () {
            this.products = [];
            this.page = 0;
            this.isLast = false;
            this.loadNext();
        }, 500)
    },

    computed: {
        ...mapActions(['setCart', 'ClearCart']),
        ...mapGetters(['currentUser'])
    },
    methods: {
        FilterCat (item) {
            const id = item.id;
            this.category = id;
        },
        setData (response) {
            const items = response.data.data;
            if (items.length === 0) {
                this.isLast = true;
                return;
            }
            if (items) {
                this.products = [
                    ...this.products,
                    ...items
                ];
            }
        },
        async loadData () {
            this.loadCategories();
            this.loading = true;
            const response = await axios.get(urls.shopProduct.shopproductlist, {
                params: {
                    search: this.search,
                    category: this.category,
                    id: 1,
                    page: this.page,
                    per_page: 20
                }
            });
            this.loading = false;
            this.setData(response);
        },
        async loadCategories () {
            this.loading = true;
            const response = await axios.get(urls.category.categoryList);
            this.categoryList = response.data.Category;
            console.log('Cat list', this.categoryList);
        },

        async loadNext () {
            if (this.loading) {
                return;
            }
            if (this.isLast) {
                return;
            }
            this.page += 1;
            await this.loadData();
        },

        async addToCart (rowData, action) {
            if (action === 'first') {
                rowData.count = 1;
            } else if (action === 'plus') {
                let qnty = parseInt(rowData.count);
                qnty = qnty + 1;
                rowData.count = qnty;
            } else {
                rowData.count -= 1;
            }

            const postData = {
                user_id: this.currentUser.id,
                product_id: rowData.id,
                quantity: rowData.count
            };
            this.$set(rowData, 'loading', true);
            const response = await axios.form(urls.cart.addCard, postData);
            this.$set(rowData, 'loading', false);
            if (response.data.error) {
            }
        },

        async removeCart (rowData) {
            const postData = {
                user_id: this.currentUser.id,
                product_id: rowData.id
            };
            this.prodLoading = true;
            const response = await axios.form(urls.cart.removeCart, postData);
            if (response.data.error === true) {
                this.loading = false;
            } else {
                rowData.count = 0;
                this.prodLoading = false;
            }
        }
    }
};

</script>
<style scoped>
div.scrollmenu {
    background-color: #fff;
    overflow: auto;
    white-space: nowrap;
    border-radius: 5px;
}

div.scrollmenu a {
    display: inline-block;
    color: #000;
    text-align: center;
    padding: 14px;
    text-decoration: none;
}

div.scrollmenu a:hover,
div.scrollmenu a:active,
div.scrollmenu a:focus {
    background-color: var(--color-primary);
    color: #ffffff;
}
</style>
