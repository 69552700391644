import Button from 'lego-framework/src/components/Button';

export default {
    extends: Button,
    props: {
        color: { type: String, default: 'danger' },
        size: { type: String, default: 'sm' },
        borderRadius: { type: String, default: '1' },
        design: { type: String, default: 'basic' }
    }
};
